import "./CookieManager.css";
import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from "js-cookie";
import { VerticallyCenteredModal } from "../../components/Modal";
import { useDispatch } from 'react-redux';
import { accept, refuse } from './cookiesSlice';

/**
 * Inspire from react-cookie-consent 
 * https://github.com/Mastermindzh/react-cookie-consent/blob/master/src/index.js
 */
function CookieBanner(props) {
    const dispatch = useDispatch();

     /**
     * Set a persistent accept cookie
     */
    function acceptCookie() {
        Cookies.set(defaultCookieConsentName, "true", { expires: 365 });
        props.accept();
        dispatch(accept());
    }

    /**
     * Remove all cookie
     */
    function declineCookie() {
        Cookies.remove(defaultCookieConsentName);
        //TODO: Set cookie consent false?
        //TODO: Remove all cookies! 

        props.decline();
        dispatch(refuse());
    }

    return (
        <div className="cookie-banner">
            <Container className="light-cursor">
                <Row className="align-items-center light-cursor">
                    <Col xs={12} md={6} xl={8} className="light-cursor">
                        {/* <i class="bi bi-fingerprint"></i> */}
                        <div className="cookie-banner-message">
                            <span>Ce site utilise des cookies pour améliorer l'expérience utilisateur.</span>
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                        <div className="btn-cookie-container d-grid gap-2 d-md-block">
                            <button onClick={acceptCookie} class="btn btn-primary btn-cookie btn-cookie-proposal">Accepter</button>
                            <button onClick={props.info} class="btn btn-primary btn-cookie">Plus d'info</button>
                            <button onClick={declineCookie} class="btn btn-primary btn-cookie">Refuser</button>
                        </div>
                    </Col>
                </Row>
            </Container>
            
        </div>
    );
}

function CookieManagementModal(props) {
    return (
        <VerticallyCenteredModal 
            title="Gestion des Cookies"
            show={props.show} 
            onHide={props.hide}
            acceptLabel="Accepter"
            onAccept={props.acceptCookie}
            declineLabel="Refuser"
            onDecline={props.declineCookie}
            moreLabel="Cookies policy"
            onMore={props.more}
            hideCloseBtn>
            <p>Afin de rendre votre visite sur notre site la plus agréable possible, nous utilisons certaines applications tiers.</p>
            <p>Les tiers utilisés et nous-mêmes utilisons des cookies ou des technologies similaires à des fins techniques et, avec votre consentement, à d'autres fins (« l'amélioration de l'expérience »), comme décrit dans la <button className="link" onClick={props.more}>politique relative aux cookies</button>.</p>
            <p>Vous pouvez librement donner, refuser ou retirer votre consentement à tout moment.</p>
            <p>Vous pouvez consentir à l'utilisation de ces technologies en vous servant du bouton « Accepter ». En fermant le présent avis, vous continuez sans accepter.</p>
        </VerticallyCenteredModal>
    );
}

function CookiePolicyModal(props) {
    return (
        <VerticallyCenteredModal 
            title="Politique relative aux cookies"
            show={props.show} 
            onHide={props.hide}
            closeLabel="Retour"
            hideHeaderCloseBtn>

            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <h5>Données personnelles traitées pour les finalités suivantes et en utilisant les services suivants</h5>
                        <br />
                    </Col>
                    
                    <Col md={6}>
                        <p>
                            <b>Affichage de contenus de plateformes externes</b> <br />
                            <span className="tier-name">Google Maps widget</span> <br />
                            Données personnelles : Données d'utilisation; Traqueur
                        </p>
                        
                    </Col>

                    <Col md={6}>
                        <p>
                            <b>Protection anti-SPAM</b> <br />
                            <span className="tier-name">Google reCAPTCHA</span> <br />
                            Données personnelles : Données personnelles : clics; Données d'utilisation; mouvements de la souris; position de défilement; réponses aux questions; Traqueur; événements de détection de mouvement; événements keypress; événements tactiles
                        </p>
                    </Col>

                    <Col xs={12}><span className="separator"></span></Col>
                    
                    <Col xs={12}><h5>Coordonnées</h5></Col>
                    <Col xs={12}>
                        <p>
                            <b>Propriétaire et Responsable du traitement</b><br />
                            Romain Arnoldy <br />
                            Rue Vivegnis, 406 - 4000 Liège, Belgique
                        </p>
                        <p><b>Courriel de contact du Propriétaire :</b> arnoldyromain@gmail.com</p>
                        <p><b>Dernière mise à jour :</b> 21 janvier 2022</p>
                    </Col>
                </Row>
            </Container>
        </VerticallyCenteredModal>
    );
}

export function CookieManager(props) {
    const [openModalCoockiePolicy, setOpenModalCoockiePolicy] = useState(false);

    const dispatch = useDispatch();

    /**
     * Set a persistent accept cookie
     */
    function acceptCookie() {
        Cookies.set(defaultCookieConsentName, "true", { expires: 365 });
        props.acceptCookie();
        dispatch(accept());
    }

    /**
     * Remove all cookie
     */
    function declineCookie() {
        Cookies.remove(defaultCookieConsentName);
        //TODO: Set cookie consent false?
        //TODO: Remove all cookies! 

        props.declineCookie();
        dispatch(refuse());
    }

    return (
        <>
            <CookieManagementModal 
                show={props.show}
                hide={props.hide} 
                acceptCookie={acceptCookie}
                declineCookie={declineCookie}
                more={() => {setOpenModalCoockiePolicy(true); props.hide();}} 
            />

            <CookiePolicyModal 
                show={openModalCoockiePolicy}
                hide={() => {setOpenModalCoockiePolicy(false); props.open();}}
            />
        </>
    );
}

/**
 * Default name of the cookie which is set by CookieConsent
 */
const defaultCookieConsentName = "CookieConsent";

export default function CookieManagerWithBanner(props) {

    const [visible, setVisible] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();

    // Similaire à componentDidMount:
    useEffect(() => {
        const { debug } = props;
    
        // if cookie undefined or debug
        const cookieConsent = getCookieConsentValue();
        if (getCookieConsentValue() === undefined || debug) {
            setVisible(true);
        }
        else {
            if (cookieConsent === "true") {
                dispatch(accept());
            }
            else {
                dispatch(refuse());
            }
        }
    }, [dispatch, props]);

    /**
     * Returns the value of the consent cookie
     * to: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
     */
    function getCookieConsentValue() {
        const name = defaultCookieConsentName;
        return Cookies.get(name);
    }

    function acceptCookie() {
        setVisible(false);
        setOpenModal(false);
    }

    function declineCookie() {
        setVisible(false);
        setOpenModal(false);
    }

    function info() {
        setOpenModal(true);
    }

    return (
        <>
            { visible && <CookieBanner accept={acceptCookie} decline={declineCookie} info={info} /> }
            <CookieManager 
                show={openModal} 
                open={() => setOpenModal(true)} 
                hide={() => setOpenModal(false)} 
                acceptCookie={acceptCookie} 
                declineCookie={declineCookie} 
            />
        </>
    );
}