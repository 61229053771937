import './Contact.css';
import React, {useEffect, useState, useRef} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Header from '../components/Header';
import GoogleMapReact from 'google-map-react';
import { properties } from '../properties';
import { IconMapPointer } from '../components/Icons';
import {isMobile} from 'react-device-detect';
import { store } from 'react-notifications-component';
import AbsoluteWrapperWithFooter from '../components/AbsoluteWrapper';
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useSelector } from 'react-redux';
import { getCookieConsent } from '../features/cookies/cookiesSlice';

function ContactInfoItem(props) {
  return(
    <div className="contact-info">
      <i className={props.icon ?? "bi bi-info-circle"}></i>
      <div className="contact-info-details">
        <span className="contact-info-title">{props.title}</span>
        <span className="contact-info-info">{props.children}</span>
      </div>
    </div>
  );
}

function ContactInfoContainer(props) {
  return(
    <div className="contact-info-container">
      <Row>
        <Col xs={12} sm={6} lg={3}>
          <ContactInfoItem title="Courriel" icon="bi bi-envelope align-self-start">
            <a href="mailto:arnoldyromain@gmail.com">arnoldyromain@gmail.com</a>
          </ContactInfoItem>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <ContactInfoItem title="Mobile" icon="bi bi-phone align-self-start"> 
            {isMobile ? <a href="tel:+32498644343">+32 (0) 498 64 43 43</a> : "+32 (0) 498 64 43 43"}
          </ContactInfoItem>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <ContactInfoItem title="Adresse" icon="bi bi-geo-alt align-self-start">
            Rue Vivegnis, 406 - 4000 Liège, Belgique
          </ContactInfoItem>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <ContactInfoItem title="TVA" icon="bi bi-tag align-self-start">
            BE 0502.859.381 
          </ContactInfoItem>
        </Col>
      </Row>
    </div>
  );
}

function GoogleMap() {

  const defaultProps = {
    center: {
      lat: 50.654806252017686,
      lng: 5.595577147813803
    },
    zoom: 15
  };

  return(
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="map-btn-tooltip">
            Ouvrir la map dans Google Maps
          </Tooltip>
        }>
        <a className="btn btn-primary btn-map" target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Rue+Vivegnis+406,+4000+Li%C3%A8ge/@50.6548054,5.595023,19z/data=!3m1!4b1!4m5!3m4!1s0x47c0f09a2d039d3d:0x4852889fcf94dbf8!8m2!3d50.6548054!4d5.5955702">
          <i className="bi bi-map"></i>
        </a>
      </OverlayTrigger>

      <GoogleMapReact
        bootstrapURLKeys={{ key: properties.googleKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}>
        <IconMapPointer
          lat={50.654806252017686}
          lng={5.595577147813803}
          containerClassName="contact-map-pointer"
          iconClassName="contact-map-pointer-icon"
        />
      </GoogleMapReact>
    </>
  )
}

function OpenStreetMap() {
  return (
    <MapContainer center={[50.65479, 5.59533]} zoom={18} scrollWheelZoom={false} style={{height: "100%"}}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[50.65479, 5.59533]}>
        <Popup>
          <b>Romain Arnoldy</b> <br /> Rue Vivegnis, 406 <br /> 4000 Liège, <br /> Belgique
        </Popup>
      </Marker>
    </MapContainer>
  );
}

function ContactMapContainer(props) {

  const canUseGoogleMap = useSelector(getCookieConsent);

  return(
    <div className="contact-map-container">
      
      {
        canUseGoogleMap 
        ? <GoogleMap></GoogleMap>
        : <OpenStreetMap></OpenStreetMap>
      }
      
    </div>
  );
}

function DisplayMessage(title, message, type) {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeInRight"],
    animationOut: ["animate__animated", "animate__fadeOutRight"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true
    }
  });
}

function ContactFormContainer() {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef();

  const onSubmitAsync = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    const token = await recaptchaRef.current.executeAsync();
    SendMail(data, e, token);
    recaptchaRef.current.reset();
  }

  const SendMail = (data, e, token) => {

    fetch(properties.backendMail, {
      method: "POST",
      body: JSON.stringify({
              "data": data,
              "g-recaptcha-response": token
            }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then((response) => { 
      console.log(response);
      return response.json();
    })
    .then((response)=> {
      console.log(response);

      if (response.status === 'success') {
        DisplayMessage("Message envoyé", "Votre message a correctement été envoyé", "success");
        e.target.reset(); // reset form
      } 
      else if(response.status === 'fail') { // Mandatory fields missing
        console.log(response);
        DisplayMessage("Erreur", "Veuillez compléter l'ensemble des champs obligatoire avant d'envoyer votre message", "danger");
      } 
      else if(response.status === 'captcha_fail') { // Sending mail php error
        console.log(response);
        DisplayMessage("Erreur", "Le captcha spécifié est invalide", "danger");
      } 
      else if(response.status === 'mail_fail') { // Sending mail php error
        console.log(response);
        DisplayMessage("Erreur", "Une erreur est survenue, veillez réessayer (Code erreur: E1002)", "danger");
      } 
      else { // An unexpected error occurred
        console.log(response);
        DisplayMessage("Erreur", "Une erreur est survenue, veillez réessayer (Code erreur: E1001)", "danger");
      }

      setLoading(false);
    })
    .catch(function(error) {
      DisplayMessage("Erreur", "Une erreur est survenue, veillez réessayer (Code erreur: E1003)", "danger");
      console.log("Error: " + error);
      setLoading(false);
    });
  }

  useEffect(() => {
    const displayRecaptchaTag = (id, url, callback) => {
      const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
      if (recaptchaElems.length) {
        for (var i = 0; i < recaptchaElems.length; i++) {
          const badge = recaptchaElems[i];
          if (badge && badge instanceof HTMLElement) {
            badge.style.visibility = 'visible';
          }
        }
      }
    }

    // Display reCAPTCHA tag
    displayRecaptchaTag();
  }, []);

  
  return (
      <div className="contact-form-container">
        <div className="contact-form-title">
          <h4>Message</h4>
          <div className="section-heading-line"></div>
        </div>
  
        <div className="contact-form-info">
          <p>Une question, un devis ou besoin d'autres informations ?</p>
          <p>N'hésitez pas à me contacter par téléphone, email ou via ce formulaire</p>
        </div>

        <Form onSubmit={handleSubmit(onSubmitAsync)}>
          <Form.Group controlId="nameGroup">
            <Form.Label>Nom et prénom</Form.Label>
            <Form.Control name="name" type="text" className={errors.name && "field-error"} placeholder="Nom et prénom" ref={register({ required: true })} />
            {errors.name && <span className="field-error">Le nom et prénom sont obligatoire</span>}
          </Form.Group>
          
          <Form.Row className="row">
              <Form.Group as={Col} className="col col-12 col-md-6" controlId="mailGroup">
                <Form.Label>Adresse email</Form.Label>
                <Form.Control name="email" type="email" placeholder="email" className={errors.email && "field-error"} ref={register({ required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                {errors.email?.type === "required" && <span className="field-error">L'adresse email est obligatoire</span>}
                {errors.email?.type === "pattern" && <span className="field-error">L'adresse email spécifié n'est pas correcte</span>}
              </Form.Group>
              <Form.Group as={Col} className="col col-12 col-md-6" controlId="phoneGroup">
                <Form.Label>Téléphone</Form.Label>
                <Form.Control name="phone" type="phone" placeholder="Téléphone" ref={register} />
              </Form.Group>
          </Form.Row>
          
          <Form.Group controlId="subjectGroup">
            <Form.Label>Sujet</Form.Label>
            <Form.Control name="subject" type="text" placeholder="Sujet" className={errors.subject && "field-error"} ref={register({ required: true })} />
            {errors.subject && <span className="field-error">Le sujet est obligtoire</span>}
          </Form.Group>
          
          <Form.Group controlId="messageGroup">
            <Form.Label>Message</Form.Label>
            <Form.Control name="message" as="textarea" rows={5} className={errors.message && "field-error"} ref={register({ required: true })} />
            {errors.message && <span className="field-error">Le message est obligtoire</span>}
          </Form.Group>

          <div className="captcha-container">
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={properties.reCaptchaKey}
              badge="bottomright"
            />
          </div>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'En cours d\'envoie...' : 'Envoyer'}
          </Button>
          <span className="form-info">Vos données ne sont ni sauvegardées, ni partagées.</span>
        </Form>
      </div>
  );
}

export default function Contact() {

  const canUseForm = useSelector(getCookieConsent);

  // Similar to componentWillUnmount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AbsoluteWrapperWithFooter>
      <Header title='Contact' />

      <Container>
        <Row>
          <Col xs={12}>
            <ContactInfoContainer />
          </Col>

          {
            canUseForm
            ? 
            <>
              <Col xs={12} lg={6}>
                <ContactFormContainer />
              </Col>
              <Col xs={12} lg={6}>
                <ContactMapContainer />
              </Col>
            </>

            : 
            <Col xs={12}>
                <ContactMapContainer />
            </Col>
          }
        </Row>
      </Container>
    </AbsoluteWrapperWithFooter>
  ); 
}

export {ContactFormContainer, ContactMapContainer};