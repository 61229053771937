import './NotFoundPage.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { IconBuilding } from '../components/Icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../components/Header';
import { animated, useSpring } from "react-spring";
import AbsoluteWrapperWithFooter from '../components/AbsoluteWrapper';

function NotFoundPage() {

  const first = useSpring({ 
    opacity: 1, 
    delay: 600,
    transform: 'translateX(0px)',
    from: { 
      opacity: 0,
      transform: 'translateX(-75px)'
    } });

  const second = useSpring({ 
    opacity: 1, 
    delay: 700,
    transform: 'translateX(0px)',
    from: { 
      opacity: 0,
      transform: 'translateX(-75px)'
    } });

  const AnimatedBuilding = animated(IconBuilding);
  const building = useSpring({ config:{ duration:500 }, opacity: 1, from: { opacity: 0 } });

  const AnimatedBtn = animated(Link);
  const btn = useSpring({ config:{ duration:500 }, delay: 800, opacity: 1, from: { opacity: 0 } });

  return (
    <AbsoluteWrapperWithFooter>
      <Header title="Page non trouvée" />
      
      <Container>
        <Row>
          <Col>
            <div className="nfpMain">
              <div className="nfpLogoContainer">
                <AnimatedBuilding style={building} className="nfpLogo" />
              </div>
              <div className="nfpAfter">
                <animated.h4 style={first}>Erreur 404</animated.h4>
                <animated.p style={second}>La page demandée n'existe pas</animated.p>
              </div>
            </div>
            <div className="nfpLink">
              <AnimatedBtn style={btn} to="/">Aller vers l'accueil</AnimatedBtn>
            </div>
          </Col>
        </Row>
      </Container>
    </AbsoluteWrapperWithFooter>
  );
}
export default NotFoundPage;