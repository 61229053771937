import './App.css';
import { BrowserRouter } from 'react-router-dom'
import RmaRouter from './Router';
import CookieManagerWithBanner from './features/cookies/CookieManager';

function App() {


// Un menu
//  Home
//  Projets
//  L'équipe
//  Contact
// Body
//  Contenu de la page
// Footer

  return (
    <BrowserRouter>
      <RmaRouter />
      {/* TODO remove debug */}
      <CookieManagerWithBanner debug={true} /> 
    </BrowserRouter>
  );
}

export default App;
