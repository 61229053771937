import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
  } from "react-router-dom";
import Menu from './menu/Menu'
import Home from './pages/Home';
import Projects from './pages/Projects';
import About from './pages/About';
import Contact from './pages/Contact';
import ProjectDetailsContainer from './pages/ProjectDetails';
import NotFoundPage from './pages/NotFoundPage';
import { animated, Transition } from 'react-spring';
import ReactNotification from 'react-notifications-component'
import Legal from './pages/Legal';

// ** Uncomment this if you want to remove page animation on mobile
//import {isMobile} from 'react-device-detect';

export function RmaRouter() {
    return (
        <Router>
            <Switch>
                <Route exact path='/' component={Home}/>
                {/* To use animation only on non-mobile, uncomment next line. (Route animation make some stange effect on menu) */}
                {/* {isMobile ? <Route component={RmaStaticSubRouter}/> : <Route component={RmaSubRouter}/> } */}
                <Route component={RmaSubRouter}/>
            </Switch>
        </Router>
    );
}

const MenuWithRouter = withRouter(Menu);
const MenuIndex = [
    {
        pathname: "/projects",
        index: 10
    },
    {
        pathname: "/project",
        index: 15
    },
    {
        pathname: "/about",
        index: 20
    },
    {
        pathname: "/contact",
        index: 30
    }
];

let CurrentIndex = 0;

function GetIndex(pathname) {

    if (pathname.includes("/project/"))
        pathname = "/project"; 

    const menu = MenuIndex.filter(m => m.pathname === pathname);

    if (menu === undefined || menu[0] === undefined || menu[0].index === undefined)
        return 0;
    else    
        return menu[0].index;
}

class RmaSubRouter extends React.Component {

    render() {
        const location = this.props.location;

        const nextIndex = GetIndex(location.pathname);
        const reverse = CurrentIndex > nextIndex;
        CurrentIndex = nextIndex;
        
        return (
            <div>
                <MenuWithRouter />
                <ReactNotification />

                <Transition
                    items={location}
                    from={{ opacity: 0, transform: reverse ? "translate(-100%,0)" : "translate(100%, 0)" }}
                    enter={{ opacity: 1,  transform: "translate(0%, 0)" }}
                    leave={{ opacity: 0, transform: reverse ? "translate(50%,0)" :"translate(-50%, 0)" }}
                >
                    {(styles, item) => (
                        <animated.div style={styles}>
                            <Switch location={item}>
                                <Route path='/projects' component={Projects}/>
                                <Route path='/about' component={About}/>
                                <Route path='/contact' component={Contact}/>
                                <Route path='/project/:id' component={ProjectDetailsContainer}/>
                                <Route exact path='/project' component={ProjectDetailsContainer}/>
                                <Route path='/legal' component={Legal}/>
                                <Route path="*" component={NotFoundPage} />
                            </Switch>  
                        </animated.div>
                    )}
                </Transition>
              </div>
          );
    }
}

// ** Uncomment this if you want to remove route animation
// function RmaStaticSubRouter() {
//     return (
//         <div>
//             <MenuWithRouter />
//             <ReactNotification />

//             <Switch>
//                 <Route path='/projects' component={Projects}/>
//                 <Route path='/about' component={About}/>
//                 <Route path='/contact' component={Contact}/>
//                 <Route path='/project/:id' component={ProjectDetailsContainer}/>
//                 <Route exact path='/project' component={ProjectDetailsContainer}/>
//                 <Route path="*" component={NotFoundPage} />
//             </Switch>
//         </div>
//     );
// }

export default withRouter(RmaRouter);