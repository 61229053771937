import './About.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../components/Header';
import Image from 'react-bootstrap/Image'
import AbsoluteWrapperWithFooter from '../components/AbsoluteWrapper';
import { properties } from '../properties';

export default class About extends React.Component {

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

    render() {

      const profile = properties.imagesUrl + "architecture-design.jpg";
      
      return (
        <AbsoluteWrapperWithFooter>
          <Header title='A propos' backgroundColor="#f8f9fa" />

          <section className="about-me-section">
            <Container>
              <Row>
                <Col  md={6}>
                  <Image rounded={true} fluid={true} src={profile} />
                </Col>
                <Col  md={6}>
                  <p>Exercitation proident amet sit laboris dolore consectetur consectetur esse cupidatat do proident exercitation. Anim duis consectetur incididunt fugiat Lorem est cupidatat elit dolore. Cillum nostrud nulla veniam mollit minim ullamco est fugiat qui anim. Aute nisi culpa qui duis consequat aliquip nulla adipisicing labore. Dolor ipsum quis eu incididunt dolore nostrud consequat. Ullamco sint ex do tempor. Eu sit aute eiusmod sunt ex ut commodo ut quis pariatur sunt.</p>
                  <p>Exercitation deserunt amet irure consectetur sunt anim labore labore quis duis est cillum nisi. Cupidatat consectetur labore cillum minim aute ex cupidatat enim. Occaecat do qui quis sunt do dolore mollit deserunt irure tempor proident nisi incididunt reprehenderit. Aliquip qui laborum cillum velit culpa ad in consectetur quis cillum elit incididunt esse. Reprehenderit aute consequat in esse labore deserunt consequat ipsum occaecat eiusmod do commodo qui. Laborum sit nisi deserunt reprehenderit dolor Lorem laborum. Fugiat commodo nulla nostrud ullamco officia.</p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="services-section">
          <Container>
            <Row>
              <div className="presentation-title">
                <h4>Mission</h4>
                <div className="section-heading-line"></div>
              </div>
            </Row>
            
            <Row className="services">
              <Col md={6} lg={3}>
                <div className="service d-block bg-white shadow rounded-lg">
                  <div className="icon d-flex align-items-center justify-content-center shadow"><i className="bi bi-clipboard-check"></i></div>
                  <div className="service-item">
                    <h3 className="heading mb-3">Diagnostic / Conception</h3>
                    <p></p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="service d-block bg-white shadow rounded-lg">
                  <div className="icon d-flex align-items-center justify-content-center shadow"><i className="bi bi-coin"></i></div>
                  <div className="service-item">
                    <h3 className="heading mb-3">Maîtrise des coûts</h3>
                    <p></p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="service d-block bg-white shadow rounded-lg">
                  <div className="icon d-flex align-items-center justify-content-center shadow"><i className="bi bi-info-circle"></i></div>
                  <div className="service-item">
                    <h3 className="heading mb-3">Assistance / Conseil</h3>
                    <p></p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="service d-block bg-white shadow rounded-lg">
                  <div className="icon d-flex align-items-center justify-content-center shadow"><i className="bi bi-tools"></i></div>
                  <div className="service-item">
                    <h3 className="heading mb-3">Réalisation / Suivi d'exécution</h3>
                    <p></p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          </section>
        </AbsoluteWrapperWithFooter>
     ); 
    }
  }
